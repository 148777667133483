// src/components/Header.js
import React from 'react';
import { Box, Flex, Center } from '@chakra-ui/react';
import Navbar from './Navbar';

function Header() {
    return (
        <Box width="100%" p={"5"}>
            <Center><Navbar /></Center>
        </Box>
    );
}

export default Header;
