import React from 'react'
import { Text, Image } from '@chakra-ui/react';

import flagMapping from './flagMapping';

function CountryFlag({ isoCode, size }) {

  const flag = flagMapping[isoCode?.toUpperCase()];
  console.log(isoCode);

  if (!flag) {
    return <Text>??</Text>;
  }

  return (
    <Image src={flag} style={{ width: size, height: size }} resizeMode="contain" />
  )
}

export default CountryFlag