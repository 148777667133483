function filterAndSortPackages(data: any, locationCode: string) {
    // Filter the packages that contain the specific location code
    const filteredPackages = data.filter((planPackage: any) => 
        planPackage.location.split(',').includes(locationCode)
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a: any, b: any) => {
        const locationCountA = a.location.split(',').length;
        const locationCountB = b.location.split(',').length;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            return a.volume - b.volume;
        }
    });

    return filteredPackages;
}

function filterAndSortPackagesOnlyOneCC(data: any, locationCode: string) {
    // Filter the packages that contain the specific location code
    const filteredPackages = data.filter((planPackage: any) => 
        planPackage.location === locationCode
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a: any, b: any) => {
        const locationCountA = a.location && typeof a.location === 'string' ? a.location.split(',').length : 0;
        const locationCountB = b.location && typeof b.location === 'string' ? b.location.split(',').length : 0;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            // Ensure volume is a number and handle possible undefined/null values
            const volumeA = typeof a.volume === 'number' ? a.volume : 0;
            const volumeB = typeof b.volume === 'number' ? b.volume : 0;
            return volumeA - volumeB;
        }
    });

    return filteredPackages;
}

function filterAndSortForRegion(data: any, region: string) {
    // Filter the packages that have a slug that starts with `region` string:
    const filteredPackages = data.filter((planPackage: any) => 
        planPackage.slug.startsWith(`${region}-`)
    );

    // Sort the packages:
    // 1. By the number of locations (ascending)
    // 2. By volume (ascending)
    filteredPackages.sort((a: any, b: any) => {
        const locationCountA = a.location && typeof a.location === 'string' ? a.location.split(',').length : 0;
        const locationCountB = b.location && typeof b.location === 'string' ? b.location.split(',').length : 0;

        if (locationCountA !== locationCountB) {
            return locationCountA - locationCountB;
        } else {
            // Ensure volume is a number and handle possible undefined/null values
            const volumeA = typeof a.volume === 'number' ? a.volume : 0;
            const volumeB = typeof b.volume === 'number' ? b.volume : 0;
            return volumeA - volumeB;
        }
    });
    return filteredPackages;
}

export { filterAndSortPackages, filterAndSortPackagesOnlyOneCC, filterAndSortForRegion };