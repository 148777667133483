import React from 'react';
import { Box, Flex, Heading, Text, Button, Image, Center } from '@chakra-ui/react';
import { AiOutlineApple, AiOutlineAndroid } from "react-icons/ai";

const MainSection = () => (
  <Box py="20">
    <Flex direction={{ base: 'column-reverse', md: 'row' }} align="center" maxW="1200px" mx="auto">
      <Box flex="1" p="5">
        <Heading size="2xl" mb="4">Explore Freely, Connect Globally</Heading>
        <Text fontSize="lg" mb="4">
          Travel with ease using Nesa. Affordable eSIM plans for seamless global connectivity.
        </Text>
        <Flex>
          <Image src="/assets/images/apple_app_store.png" alt="Download on the App Store" mr="4" width="150px" />
          <Image src="/assets/images/google_play_store.png" alt="Get it on Google Play" width="150px" />
        </Flex>
      </Box>
      <Box flex="1" >
        <Center>
          <Image src="/assets/images/hero_image.png" alt="Main" maxW={"75%"} />
        </Center>
      </Box>
    </Flex>
  </Box>
);

export default MainSection;
