import React, { useState } from "react";
import {
	Box,
	Flex,
	Text,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	useDisclosure,
	Heading
} from "@chakra-ui/react";

const CountryCard = ({ plan }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const formatPrice = (price) => {
		const currency = localStorage.getItem('currency') || 'USD';
		const rate = parseFloat(localStorage.getItem('conversionRate') || 1);
		const convertedPrice = price * rate;

		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: currency
		}).format(convertedPrice);
	};

	return (
		<Box
			p="6"
			bg="gray.800"
			borderRadius="md"
			boxShadow="md"
			mb="4"
			width="full"
		>
			<Flex direction="column" align="center" mb="4">
				<Text fontSize="lg" fontWeight="bold" color="white">
					{(plan.volume / 1024 ** 3).toFixed(2)} GB
				</Text>
				<Text color="gray.400">
					{plan.duration} {plan.durationUnit}
				</Text>
				<Text fontSize="lg" fontWeight="bold" color="white">
					{formatPrice(plan.price / 10000)}
				</Text>
				<Text color="gray.400">
					{plan.locationNetworkList[0]?.operatorList[0]?.operatorName}
				</Text>
				<Button mt="2" colorScheme="purple" onClick={onOpen}>
					See Details
				</Button>
			</Flex>

			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{plan.name}</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Text>
							<b>Description:</b> {plan.description}
						</Text>
						<Text>
							<b>Speed:</b> {plan.speed}
						</Text>
						<Text>
							<b>Unused Valid Time:</b> {plan.unusedValidTime} days
						</Text>
						<Heading size="sm" mt="4" mb="2">
							Locations and Networks
						</Heading>
						{plan.locationNetworkList.map((location) => (
							<Box key={location.locationName} mb="4">
								<Text color="gray.800">
									<b>{location.locationName}:</b>
								</Text>
								{location.operatorList.map((operator, index) => (
									<Text key={index} color="gray.600">
										- {operator.operatorName} ({operator.networkType})
									</Text>
								))}
							</Box>
						))}
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="purple" mr={3} onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
};

export default CountryCard;
