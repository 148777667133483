import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Flex, Heading, Text, Stack, Grid, Button, useBreakpointValue } from '@chakra-ui/react';

import CountryCard from './CountryCard';
import { RiArrowGoBackLine } from "react-icons/ri";

import CountryFlag from './CountryFlag.js';

import ApiClient from '../../utils/API/api';
import countryUtils from '../../utils/Plans/country';
import { filterAndSortPackages } from '../../utils/Plans/plans.ts';

const Country = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [plans, setPlans] = useState([]);
	const { countryCode } = useParams();
	const navigate = useNavigate();
	const [countryPlans, setCountryPlans] = useState([]);
	const isMobile = useBreakpointValue({ base: true, md: false });

	useEffect(() => {
		const fetchData = async () => {
			const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
			try {
				const response = await apiClient.get('/plans');
				setPlans(response.data);
				console.log(response.data);
				const countrySpecificPlans = response.data.obj.packageList.filter((plan) => plan.location.includes(countryCode.toUpperCase()));
				console.log(filterAndSortPackages(countrySpecificPlans))
				setCountryPlans(filterAndSortPackages(countrySpecificPlans, countryCode.toUpperCase()));
				setIsLoading(false);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, [countryCode]);

	const filterPlans = (type) => {
		switch (type) {
			case 'local':
				return countryPlans.filter(plan => plan.location === countryCode.toUpperCase());
			case 'regional':
				return countryPlans.filter(plan => plan.location !== countryCode.toUpperCase() && !plan.slug.startsWith('GL'));
			case 'global':
				return countryPlans.filter(plan => plan.slug.startsWith('GL'));
			default:
				return countryPlans
		}
	}

	const renderPlans = (plansArray) => (
		plansArray.length > 0 ? plansArray.map((plan) => (
			<CountryCard key={plan.packageCode} plan={plan} />
		)) : (
			<Text>No plans available</Text>
		)
	);

	if (isLoading) {
		return (
			<Box py="20" px="10">
				<Flex direction="column" align="center" maxW="1200px" mx="auto">
					<Heading size="xl" mb="10">Loading...</Heading>
				</Flex>
			</Box>
		);
	}

	return (
		<Box py="20" px="10">
			<Flex direction="column" align="center" maxW="1200px" mx="auto">
				{/* create back button with heading title beside it */}
				<Flex display={"flex"} alignItems={"center"} gap={5} mb={20}>
					{!isMobile && (<Button onClick={() => navigate(-1)} value={'link'} leftIcon={<RiArrowGoBackLine />} variant='ghost'>Back</Button>)}
					<Heading size="lg">
						<Flex justify={"center"} alignItems={"center"} gap={5}>
							<CountryFlag isoCode={countryCode} size={32} />
							{isMobile ? '' : 'Plans for '}{countryUtils.getName(countryCode.toUpperCase())}
						</Flex>


					</Heading>
				</Flex>
				<Stack spacing="8" w="100%">
					<Heading size="lg" mb="4">Local Plans</Heading>
					<Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>{renderPlans(filterPlans('local'))}</Grid>

					<Heading size="lg" mt="8" mb="4">Regional Plans</Heading>
					<Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>{renderPlans(filterPlans('regional'))}</Grid>

					<Heading size="lg" mt="8" mb="4">Global Plans</Heading>
					<Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>{renderPlans(filterPlans('global'))}</Grid>
				</Stack>
			</Flex>
		</Box>
	);
};

export default Country;
