// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Switch } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import Plans from './pages/Plans';
import Country from './components/Plans/Country';
import FAQ from './pages/FAQ';
import QuestionDetails from './pages/QuestionDetails';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import Error from './pages/Error';

import './App.scss';

import Header from './components/Header';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/plans" element={<Plans />} />
        <Route path="/plans/:countryCode" element={<Country />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/faq/q/:questionId" element={<QuestionDetails />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<Blog />} />
        <Route path="/*" element={<Error />} />
      </Routes>
      <Footer />
    </div>);
}

export default App;
