import React from 'react';
import { Box, Flex, Heading, Text, Image, VStack } from '@chakra-ui/react';

const StepsSection = () => (
  <Box py="20">
    <Flex direction="column" align="center" maxW="1200px" mx="auto">
      <Heading size="xl" p="5" mb="10">Simple Steps to Global Connectivity</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Image src="/assets/images/screenshots/splash_screen.png" alt="Step 1" maxW="200px" mx="auto" />
          <Heading size="md" mt="4">Download the App</Heading>
          <Text mt="2">A world of connectivity in your palm.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Image src="/assets/images/screenshots/checkout_screen.png" alt="Step 2" maxW="200px" mx="auto" />
          <Heading size="md" mt="4">Select & Checkout</Heading>
          <Text mt="2">Select your destination and checkout.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Image src="/assets/images/screenshots/setup_screen.png" alt="Step 3" maxW="200px" mx="auto" />
          <Heading size="md" mt="4">Connect Freely</Heading>
          <Text mt="2">Add your eSIM to your device. Enjoy your choice of instant internet access.</Text>
        </Box>
      </Flex>
    </Flex>
  </Box>
);

export default StepsSection;
