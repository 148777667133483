import React from 'react';
import { Box, Flex, Heading, Text, Image, Icon } from '@chakra-ui/react';
import { MdOutlinePhonelinkSetup, MdModeOfTravel } from "react-icons/md";
import { TbHandClick } from "react-icons/tb";



const FeatureSection = () => (
  <Box bg="gray.50" py="20">
    <Flex direction="column" align="center" maxW="1200px" mx="auto">
      <Heading size="xl" p="5" mb="10">Discover the Future of Mobile Connectivity: eSIM</Heading>
      <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" w="100%">
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdOutlinePhonelinkSetup} w={50} h={50} />
          <Heading size="md" mt="4">Seamless Setup</Heading>
          <Text mt="2">Download the app, choose your plan, and activate your eSIM in minutes.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={TbHandClick} w={50} h={50} />
          <Heading size="md" mt="4">Activate Anywhere, Anytime</Heading>
          <Text mt="2">No more SIM card swaps. Activate your eSIM on the go, wherever you are.</Text>
        </Box>
        <Box flex="1" textAlign="center" p="5">
          <Icon as={MdModeOfTravel} w={50} h={50} />
          <Heading size="md" mt="4">Travel Smarter</Heading>
          <Text mt="2">Enjoy affordable data plans for your travels, with multiple carrier options.</Text>
        </Box>
      </Flex>
    </Flex>
  </Box>
);

export default FeatureSection;
