import React from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';

const FAQSection = () => (
  <Box py="20">
    <Box maxW="1200px" mx="auto">
      <Heading size="xl" mb="10" textAlign="center">Frequently Asked Questions</Heading>
      <Accordion allowMultiple>
        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">Do I have to activate data roaming on my device?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb="4">
            Yes, you need to activate data roaming for the eSIM to work properly.
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionButton>
            <Box flex="1" textAlign="left">When do the days of my data plan start counting?</Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb="4">
            The days start counting from the moment the eSIM is activated.
          </AccordionPanel>
        </AccordionItem>

        {/* Add more FAQs here */}
      </Accordion>
    </Box>
  </Box>
);

export default FAQSection;
