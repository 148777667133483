import React, { useState, useEffect } from 'react';
import { Stack, Box, Flex, Heading, VStack, Text, Input, List, ListItem, useBreakpointValue, Button, useDisclosure, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';
import DestinationCard from '../components/Plans/DestinationCard';
import SearchBar from '../components/Plans/SearchBar';

import ApiClient from '../utils/API/api';
import countryUtils from '../utils/Plans/country';

const destinations = [
  {
    country: 'Italy',
    price: '2.99$',
    flagUrl: 'path_to_italy_flag.png',
    imageUrl: 'path_to_italy_image.png',
  },
  {
    country: 'Afghanistan',
    price: '2.99$',
    flagUrl: 'path_to_afghanistan_flag.png',
    imageUrl: 'path_to_afghanistan_image.png',
  },
  {
    country: 'India',
    price: '3.25$',
    flagUrl: 'path_to_india_flag.png',
    imageUrl: 'path_to_india_image.png',
  },
  {
    country: 'Singapore',
    price: '1.50$',
    flagUrl: 'path_to_singapore_flag.png',
    imageUrl: 'path_to_singapore_image.png',
  },
  {
    country: 'Italy',
    price: '2.99$',
    flagUrl: 'path_to_italy_flag.png',
    imageUrl: 'path_to_italy_image.png',
  },
  {
    country: 'Afghanistan',
    price: '2.99$',
    flagUrl: 'path_to_afghanistan_flag.png',
    imageUrl: 'path_to_afghanistan_image.png',
  },
  {
    country: 'India',
    price: '3.25$',
    flagUrl: 'path_to_india_flag.png',
    imageUrl: 'path_to_india_image.png',
  },
  {
    country: 'Singapore',
    price: '1.50$',
    flagUrl: 'path_to_singapore_flag.png',
    imageUrl: 'path_to_singapore_image.png',
  },
  {
    country: 'Italy',
    price: '2.99$',
    flagUrl: 'path_to_italy_flag.png',
    imageUrl: 'path_to_italy_image.png',
  },
  {
    country: 'Afghanistan',
    price: '2.99$',
    flagUrl: 'path_to_afghanistan_flag.png',
    imageUrl: 'path_to_afghanistan_image.png',
  },
  {
    country: 'India',
    price: '3.25$',
    flagUrl: 'path_to_india_flag.png',
    imageUrl: 'path_to_india_image.png',
  },
  {
    country: 'Singapore',
    price: '1.50$',
    flagUrl: 'path_to_singapore_flag.png',
    imageUrl: 'path_to_singapore_image.png',
  },
];

const PlansScreen = () => {
  const [data, setData] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState('');
  const [localitySelector, setLocalitySelector] = useState([
    {
      name: 'Local',
      selected: true,
    },
    {
      name: 'Regional',
      selected: false,
    },
    {
      name: 'Global',
      selected: false,
    }
  ]);
  const [regionSelector, setRegionSelector] = useState([
    {
      name: 'Europe',
      code: 'EU',
      selected: false,
    },
    {
      name: 'Asia',
      code: 'AS',
      selected: false,
    },
    {
      name: 'North America',
      code: 'NA',
      selected: false,
    },
    {
      name: 'South America',
      code: 'SA',
      selected: false,
    },
    {
      name: 'Africa',
      code: 'AF',
      selected: false,
    },
    {
      name: 'Middle East',
      code: 'ME',
      selected: false,
    }

  ]);

  useEffect(() => {
    const fetchData = async () => {
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
      try {
        const response = await apiClient.get('/plans');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // If locality selector is selected, filter and sort by locality
    setDestinations(countryUtils.searchCountry("", []));
  }, [localitySelector, regionSelector]);

  useEffect(() => {
    // Filter and sort by search query
    setDestinations(countryUtils.searchCountry(searchQuery, []) ?? []);
  }, [searchQuery])

  return (
    <Flex direction="column" p="5">
      <Flex justifyContent={isMobile ? 'space-around' : 'center'} alignItems="center" mb="5" >
        <SearchBar placeholder="Where do you need internet?" showIcon={!isMobile} width={isMobile ? '85%' : '65%'} setSearchQuery={setSearchQuery} />
        {/* {isMobile && (
          <Button leftIcon={<FaFilter />} iconSpacing={"0"} onClick={onOpen}></Button>
        )} */}
      </Flex>

      <Flex flex="1" flexDirection={isMobile ? 'column' : 'row'} minH="100vh">
        {/* {!isMobile && (
          <Box width="20%" p="5" bg="gray.20" height="100vh">
            <VStack align="start" spacing="5">
              <Box>
                <Heading size="md" mb="2">Explore</Heading>
                <List spacing={2}>
                  {localitySelector.map((plan) => (
                    <ListItem key={plan.name}>
                      <Button
                        variant={plan.selected ? 'solid' : 'ghost'}
                        borderRadius={'full'}
                        colorScheme={plan.selected ? 'brand.500' : 'brand.700'}
                        onClick={() => {
                          setLocalitySelector(
                            localitySelector.map((item) => {
                              if (item.name === plan.name) {
                                // if selected, deselect
                                if (plan.selected) {
                                  return { ...item, selected: false };
                                }
                                return { ...item, selected: true };
                              }
                              return { ...item, selected: false };
                            })
                          );
                        }}
                      >
                        {plan.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box>
                <Heading size="md" mb="2">Continents</Heading>
                <List spacing={2}>
                  {regionSelector.map((plan) => (
                    <ListItem key={plan.name}>
                      <Button
                        variant={plan.selected ? 'solid' : 'ghost'}
                        minWidth="0"
                        borderRadius={'full'}
                        colorScheme={plan.selected ? 'purple' : 'gray'}
                        onClick={() => {
                          setRegionSelector(
                            regionSelector.map((item) => {
                              if (item.name === plan.name) {
                                // if selected, deselect
                                if (plan.selected) {
                                  return { ...item, selected: false };
                                }
                                return { ...item, selected: true };
                              }
                              return { ...item, selected: false };
                            })
                          );
                        }}
                      >
                        {plan.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </VStack>
          </Box>
        )} */}

        <Box flex="1" p="5" overflowY="auto">
          <Flex wrap="wrap" gap="2" justifyContent={"center"}>
            {destinations.map((destination) => {
              // select random landmark image from destination.landmarks
              let landmark;
              if (destination?.landmarks) {
                landmark = destination?.landmarks[Math.floor(Math.random() * destination?.landmarks.length)];
              } else {
                landmark = "";
              }
              return (
                <DestinationCard
                  key={destination.code}
                  code={destination.code}
                  country={destination.name}
                  price={destination.price}
                  landmarkUrl={process.env.PUBLIC_URL + '/assets/images/landmarks/' + landmark + '.png'}
                />
              )
            })}
          </Flex>
        </Box>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Filters</DrawerHeader>
            <DrawerBody>
              <VStack align="start" spacing="5">
                <Box>
                  <Heading size="md" mb="2">Explore</Heading>
                  <List spacing={2}>
                    <ListItem>Local</ListItem>
                    <ListItem>Regional</ListItem>
                    <ListItem>Global</ListItem>
                    <ListItem>Infinite</ListItem>
                  </List>
                </Box>
                <Box>
                  <Heading size="md" mb="2">Continents</Heading>
                  <List spacing={2}>
                    <ListItem>Europe</ListItem>
                    <ListItem>Asia</ListItem>
                    <ListItem>North America</ListItem>
                    <ListItem>South America</ListItem>
                    <ListItem>Africa</ListItem>
                    <ListItem>Oceania</ListItem>
                  </List>
                </Box>
              </VStack>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Close
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Flex>
  );
};

export default PlansScreen;
