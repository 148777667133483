import React, { useState } from 'react';
import {
	Box,
	Flex,
	Button,
	useColorModeValue,
	Container,
	Link,
	Image,
	IconButton,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	Text,
	useBreakpointValue
} from '@chakra-ui/react';
import { RxHamburgerMenu } from "react-icons/rx";
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'


const Navbar = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [placement, setPlacement] = useState('right');
	const btnRef = React.useRef();
	const navigate = useNavigate();
	const isMobile = useBreakpointValue({ base: true, md: false });

	const linkColor = useColorModeValue("#2e0a37", "#2e0a37");

	return (
		<Box
			bg={useColorModeValue('white', 'gray.800')}
			px={4}
			borderRadius={isMobile ? "0" : "full"}
			w={isMobile ? "100%" : "95%"}
			maxW={isMobile ? "auto" : "1200px"}
			boxShadow='base' // Initial shadow
		>
			<Container maxW="container.xl">
				<Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
					<Box display={'flex'} flexDir={"row"} alignItems={"center"} onClick={() => {
						navigate("/");
					}}>
						<Image src="/assets/images/logo.png" alt="Nesa Mobile" h="50px" />
						<Text fontWeight={"bold"} color={"#2e0a37"} _hover={{ cursor: "pointer" }}>Nesa</Text>
					</Box>

					<Flex alignItems={'center'}>
						<IconButton
							ref={btnRef}
							icon={<RxHamburgerMenu />}
							display={{ base: 'flex', md: 'none' }}
							onClick={onOpen}
							size="md"
							aria-label={'Open Menu'}
							fontSize="20px"
						// color={linkColor}
						/>
						<Box display={{ base: 'none', md: 'flex' }} ml={10}>
							{/* <Link href="#" px={2} color={linkColor} fontWeight="bold">Product</Link>
							<Link href="#" px={2} color={linkColor} fontWeight="bold">Templates</Link>
							<Link href="#" px={2} color={linkColor} fontWeight="bold">Resources</Link>
							<Link href="#" px={2} color={linkColor} fontWeight="bold">Pricing</Link>
							<Link href="#" px={2} color={linkColor} fontWeight="bold">Contact sales</Link> */}
							<Button variant='ghost' as={ReactRouterLink} to='/plans'>
								Destinations
							</Button>
							<Button variant='ghost' as={ReactRouterLink} to='/faq'>
								FAQ
							</Button>
							<Button variant='ghost' as={ReactRouterLink} to='/about'>
								About
							</Button>
							<Button variant='ghost' as={ReactRouterLink} to='/contact'>
								Contact
							</Button>
						</Box>
						{/* <Flex display={{ base: 'none', md: 'flex' }}>
							<Button colorScheme="purple" variant="solid" size="sm" ml={4}>
								Log in
							</Button>
							<Button colorScheme="purple" variant="outline" size="sm" ml={2}>
								Sign up
							</Button>
						</Flex> */}
					</Flex>
				</Flex>
			</Container>

			<Drawer
				isOpen={isOpen}
				placement={placement}
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>Menu</DrawerHeader>

					<DrawerBody>
						<Link href="#" p={2} display="block" color={linkColor} fontWeight="bold" onClick={() => {
							navigate("/");
							onClose();
						}}>Home</Link>
						<Link href="#" p={2} display="block" color={linkColor} fontWeight="bold" onClick={() => {
							navigate("/plans");
							onClose();
						}}>Plans</Link>
						<Link href="#" p={2} display="block" color={linkColor} fontWeight="bold" onClick={() => {
							navigate("/faq");
							onClose();
						}}>FAQ</Link>
						<Link href="#" p={2} display="block" color={linkColor} fontWeight="bold" onClick={() => {
							navigate("/about");
							onClose();
						}}>About</Link>
						<Link href="#" p={2} display="block" color={linkColor} fontWeight="bold" onClick={() => {
							navigate("/contact");
							onClose();
						}}>Contact</Link>
					</DrawerBody>

					{/* <DrawerFooter>
						<Button colorScheme="purple">Log in</Button>
						<Button colorScheme="purple" ml={2} variant="outline">Sign up</Button>
					</DrawerFooter> */}
				</DrawerContent>
			</Drawer>
		</Box >
	);
};

export default Navbar;

