import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";
import MainSection from '../components/Home/MainSection';
import FeatureSection from '../components/Home/FeatureSection';
import StepsSection from '../components/Home/StepsSection';
import PlanSection from '../components/Home/PlanSection';
import FAQSection from '../components/Home/FAQSection';

import ApiClient from '../utils/API/api';

// Create simple react home page
function Home() {
  useEffect(() => {
    const fetchData = async () => {
      const apiClient = new ApiClient(process.env.REACT_APP_API_ENDPOINT, () => { return ""; });
      try {
        const response = await apiClient.get('/plans');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box>
      <MainSection />
      <FeatureSection />
      <StepsSection />
      <PlanSection />
      <FAQSection />
    </Box>
  );
}

export default Home;
