import React, { useState, useEffect } from 'react';
import Markdown from 'marked-react';
import posts from './posts.json';
import { useParams } from 'react-router-dom';
import { Heading, Text, Box, Link, Code } from '@chakra-ui/react';

const customRenderer = {
  heading: (children, level) => {
    const sizes = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'];
    return <Heading as={`h${level}`} size={sizes[level - 1]}>{children}</Heading>;
  },
  paragraph: (props) => {
    return <Text mb={4}>{props.children}</Text>;
  },
  code: (props) => {
    return <Code p={2}>{props.children}</Code>;
  },
  blockquote: (props) => {
    return <Box as="blockquote" p={2} borderLeft="4px solid gray">{props.children}</Box>;
  },
  link: (props) => {
    return <Link color="teal.500" href={props.href}>{props.children}</Link>;
  },
  // Add more custom renderers as needed
};

const MarkdownViewer = () => {
  const { slug } = useParams();
  const [content, setContent] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const getContent = async () => {
      const post = posts.find(p => p.slug === slug);
      if (post) {
        const response = await fetch(`/posts/${post.filename}`);
        const text = await response.text();
        setContent(text);
      } else {
        setError(true);
      }
    }
    getContent();
  }, [slug]);

  if (error || content == '') {
    return <div>Post not found.</div>;
  }

  return <Markdown value={content} renderer={customRenderer} />
};

export default MarkdownViewer;
